import { ComponentProps, ReactElement, useEffect, useMemo } from 'react';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { brandCode, cimIdentifier, cimLanguage, gtmCodes, navigationVersion } from '@hubcms/brand';

import { PianoLoader, extractPianoHeadPropsFromCook } from '@hubcms/feature-meta-tags';
import { KaChingProvider, getKaChingPropsFromCookData } from '@hubcms/data-access-ads';
import {
  calculateCacheTag,
  setCacheTag,
  extractCookResponse,
  verifyArticleUrl,
  getArticleEceUrl,
  getPageTitle,
  getPageDataFromCook,
  mapKeyValueListsToObjects,
  isValidCookSlug,
  setResponseHeaders,
  GraphqlMenuData,
  getMainContent,
} from '@hubcms/feature-cook';
import { useModal } from '@hubcms/data-access-modal';
import { log, getLinkHeaders, setCacheHeaders, error } from '@hubcms/utils-monitoring';
import { AirshipHead } from '@hubcms/feature-webpush';
import { DidomiHead } from '@hubcms/feature-didomi';
import { CreativeWorkSchema } from '@hubcms/feature-structured-data';
import { Modal } from '@hubcms/ui-modal';
import { Topics } from '@hubcms/ui-navigation';
import { Container } from '@hubcms/ui-container';
import { TrackPageView, GTMDataLayer, GTMHead, CimTracker } from '@hubcms/feature-tracking';
import { CookData, isRssFeed, isTagPage } from '@hubcms/domain-cook';
import { mapNavItem } from '@hubcms/utils-navigation';
import { useAuth } from '@hubcms/data-access-auth';
import { CustomConsent } from '@hubcms/feature-custom-consent';
import { CookProvider } from '@hubcms/data-access-cook';
import { isNotFoundStatus, isOkStatus, isMovedPermanentlyStatus, isClientErrorStatus } from '@hubcms/utils-http';
import { getServerConfig } from '@hubcms/data-access-env-config';
import { mapMySectionToTopics } from '@hubcms/utils-my-section';

import { exposeEnvVarsToClient, getParamsForCookRequest, getAllPageDataPromises, getScenarioFromAppContext } from '../utils';
import { ResolutionComponent } from '../templates/ResolutionComponent';
import { SocialMetaTags, RSSLink, MetaTagsAppLinks, MetaTags } from '../components/PageHead';
import CanonicalLink from '../components/PageHead/CanonicalLink';
import { PageProps } from '../domain/PageProps';
import { createClientSideEnv } from '../utils/createClientSideEnv';

import styles from './pageStyles.module.scss'; // eslint-disable-line import/max-dependencies

const TOPICS_LABEL_KEY = 'navigation.topicslabel';

export default function Page(props: PageProps<CookData>): ReactElement {
  if (!props.data.resolution?.context) {
    throw Error(`Template not found, status ${props.status}, slug ${props.slug}`);
  }

  const { isOpen } = useModal();
  const { isAuthenticated, login, isLoading } = useAuth();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.modalOpen);
    } else {
      document.body.classList.remove(styles.modalOpen);
    }
  }, [isOpen]);

  const topicsProps: ComponentProps<typeof Topics> = useMemo(
    () => ({
      topics: (props.data.header?.topics || []).map(mapNavItem).map(mapMySectionToTopics),
      topicsLabel: props.data.resolution?.section?.parameters?.find(({ key }) => key === TOPICS_LABEL_KEY)?.value ?? '',
    }),
    [props.data],
  );
  const showTopics = useMemo(() => {
    return (
      !!props.data.header?.topics?.length &&
      props.data.resolution?.section?.uniqueName === 'ece_frontpage' &&
      props.data.resolution?.context === 'sec' &&
      !isTagPage(props.data) &&
      navigationVersion === 'v1'
    );
  }, [props.data]);

  const kaChingProps = useMemo(() => getKaChingPropsFromCookData(props.data), [props.data]);

  return (
    <CookProvider value={props.data}>
      <MetaTags cookData={props.data} />
      <GTMDataLayer />
      <DidomiHead />
      <CustomConsent
        isAuthenticated={isAuthenticated}
        isAuthLoading={isLoading}
        login={login}
        sectionParams={props.data.sectionParams}
      />
      <CreativeWorkSchema />
      <TrackPageView pagetitle={props.metaData?.title || ''} cookData={props.data} brandCode={brandCode} />
      <CimTracker identifier={cimIdentifier} lang={cimLanguage} subs={props.data.sectionParams['analytics.CIM.subsection']} />
      <RSSLink cookData={props.data} />
      {!props.isPreview && <PianoLoader {...extractPianoHeadPropsFromCook(props.data)} />}
      <SocialMetaTags cookData={props.data} />
      <CanonicalLink cookData={props.data} />
      <MetaTagsAppLinks cookData={props.data} />
      <AirshipHead />
      {!props.isPreview && <GTMHead gtmCodes={gtmCodes} />}
      {/* <GTMBody gtmCodes={gtmCodes}  /> see https://github.com/facebook/react/issues/25627 */}
      <KaChingProvider {...kaChingProps}>
        {showTopics && (
          <Container as="nav" fullWidthSm fullWidthMd>
            <Topics {...topicsProps} />
          </Container>
        )}
        <ResolutionComponent cookData={props.data} />
      </KaChingProvider>
      <Modal />
    </CookProvider>
  );
}

// @ts-expect-error TS2322
export const getServerSideProps: GetServerSideProps<PageProps<CookData>> = async (context: GetServerSidePropsContext) => {
  const { slug, cookURL, cookPath, requestHost } = getParamsForCookRequest(context);

  if (!isValidCookSlug(slug)) {
    return { notFound: true };
  }
  const {
    pageDataPromise,
    menu: { defaultMenuData, menuDataPromise },
    weatherDataPromise,
  } = getAllPageDataPromises(getPageDataFromCook(context.req, context.res, cookPath, requestHost));

  const {
    responses: { page: cookResponse },
    data: responseBody,
    isArticlePreview,
  } = await pageDataPromise;

  // TODO: A lot of this could move into @hubcms/cook, so that we can import only a couple functions from there instead of 11
  if (isNotFoundStatus(cookResponse.status)) {
    const articleEceUrl = getArticleEceUrl(`${requestHost}/${cookPath}`);

    if (articleEceUrl) {
      // see whether the article has a different slug and if it does, redirect there
      const articleEceResponse = await getMainContent(articleEceUrl);
      // @ts-expect-error TS2345
      const articleEceData = await extractCookResponse(articleEceResponse, context.req);
      if (isOkStatus(articleEceResponse.status) && articleEceData?.data?.context?.href) {
        const currentUrl = cookPath;
        const redirectUrl = new URL(articleEceData?.data?.context?.href);
        // checking for article URLs that have a slug with an empty title which causes a redirect loop
        if (redirectUrl.pathname.replace(/\/\//g, '/') !== currentUrl) {
          return {
            redirect: {
              destination: articleEceData.data.context.href,
            },
          };
        }
      }
    }
    return {
      notFound: true,
    };
  }

  if (isMovedPermanentlyStatus(cookResponse.status)) {
    return {
      redirect: {
        destination: cookResponse.headers.get('Location')?.toString(),
        permanent: true,
      },
    };
  }

  if (isClientErrorStatus(cookResponse.status)) {
    return {
      notFound: true,
    };
  }

  const articleRedirect = verifyArticleUrl(responseBody, context.req);
  if (articleRedirect) return articleRedirect;

  const [sectionParams, declaredParams] = mapKeyValueListsToObjects(
    responseBody.resolution.section.parameters,
    !isRssFeed(responseBody) ? responseBody.resolution.section.declaredParams : [],
  );

  setCacheTag(context.res, calculateCacheTag(responseBody));

  // Tag page workaround
  // Check if the Cook response has a tag key
  // and if it is `null` or the name is empty then there is no tag for the current url
  // This is a workaround while StiboDX improve the functionality of the Cook Tag extension
  if (isTagPage(responseBody) && !responseBody.tagTitle) {
    log('Tag url: Cook could not find the tag - return a 404: ', `${cookURL}${cookPath}`);
    return {
      notFound: true,
    };
  }

  let header: GraphqlMenuData['header'];
  let footer: GraphqlMenuData['footer'];

  try {
    ({ header, footer } = await menuDataPromise);
  } catch (err) {
    error(err);
    ({ header, footer } = defaultMenuData);
  }

  try {
    exposeEnvVarsToClient(['MH_ENV']);
  } catch (e) {
    log(e);
    process.env.NEXT_PUBLIC_MH_ENV = process.env.MH_ENV;
  }

  const weatherData = await weatherDataPromise;

  if (process.env.CUE_WEB_DOMAIN) {
    context.res.setHeader(
      'Content-Security-Policy',
      `frame-ancestors ${process.env.CUE_WEB_DOMAIN} https://preview-bundle.mediahuisgroup.com https://test-bundle.dev-news-components.mediahuiscloud.net https://preview-bundle.dev-news-components.mediahuiscloud.net https://test-bundle.mediahuisgroup.com http://localhost:5173`,
    );
  }

  context.res.setHeader('link', getLinkHeaders());

  const todaysDate = new Date().toISOString();

  const cookData: CookData = {
    ...responseBody,
    header,
    footer,
    sectionParams,
    declaredParams,
    todaysDate,
  };

  const { cacheSMaxAge, cacheSwrMaxAge } = getServerConfig();
  setCacheHeaders({ req: context.req, res: context.res, sMaxAge: cacheSMaxAge, swrMaxAge: cacheSwrMaxAge });
  setResponseHeaders(cookData, context.res);
  return {
    props: {
      scenario: getScenarioFromAppContext(context),
      data: cookData,
      metaData: {
        title: getPageTitle(cookData),
      },
      status: cookResponse.status,
      slug,
      env: createClientSideEnv(),
      isPreview: isArticlePreview,
      weatherData,
    },
  };
};
